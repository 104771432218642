(function () {
  const modalBacklog = document.querySelector('.modal-backlog');
  const modalVideo = document.querySelector('.modal__video');

  const close = document.createElement('button');
  close.classList.add('modal__close-button');
  close.setAttribute('aria-label', 'Close video');
  modalVideo.append(close);

  playModalVideo = function (playSelector, videoSources, label) {
    const play = document.querySelector(playSelector);
    play.setAttribute('aria-label', 'Open video');

    play.addEventListener('click', function () {
      const oldVideo = modalVideo.querySelector('video');
      if (oldVideo) {
        oldVideo.remove();
      }
      const video = document.createElement('video');
      video.setAttribute('aria-label', label);
      video.setAttribute('controls', '');

      videoSources.forEach((source) => {
        const extension = source.slice(
          source.lastIndexOf('.') + 1,
          source.length
        );
        const sourceTag = document.createElement('source');
        sourceTag.setAttribute('src', source);
        sourceTag.setAttribute('type', `video/${extension}`);
        video.append(sourceTag);
      });

      modalVideo.append(video);

      modalBacklog.classList.add('active');
      video.play();
    });

    close.addEventListener('click', function () {
      const video = modalVideo.querySelector('video');
      video.pause();
      video.currentTime = 0;
      modalBacklog.classList.remove('active');
    });
  };

  playModalVideo(
    '.hero-section .play-video-button',
    ['/assets/videos/index-page.webm', '/assets/videos/index-page.mp4'],
    'Welcome to Coinis!'
  );

  playModalVideo(
    '.advertising-section .play-video-button',
    [
      '/assets/videos/advertising-platform/advocates.webm',
      '/assets/videos/advertising-platform/advocates.mp4',
    ],
    'Welcome to Coinis!'
  );

  playModalVideo(
    '.promo-video-section .play-video-button',
    ['/assets/videos/Our-story-Coinis-Culture-2022-Join-Us.mp4'],
    'Welcome to Coinis!'
  );
})();
